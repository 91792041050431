<template>
    <!-- Footer  -->
        <div class="footer-copyright-area">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="footer-copy-right">
                            <p>Copyright © 2021 <a href="https://lce-ci.com">LCE</a> All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
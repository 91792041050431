<template>
<div class="left-sidebar-pro">
        <nav id="sidebar" class="">
            <div class="sidebar-header">
                <a href="/"><img class="main-logo" style="width:200px;" src="https://lce-ci.com/assets/img/l.png" alt="" /></a>
                <strong><img src="assets/img/logo/logosn.png" alt="" /></strong>
            </div>
			<div class="nalika-profile">
				<div class="profile-dtl">
					<h2>{{ name }}</h2>
                    <h2>{{ email }}</h2>
				</div>
				<div class="profile-social-dtl">
					<ul class="dtl-social">
						<li><a href="#"><i class="icon nalika-facebook"></i></a></li>
						<li><a href="#"><i class="icon nalika-twitter"></i></a></li>
						<li><a href="#"><i class="icon nalika-linkedin"></i></a></li>
					</ul>
				</div>
			</div>
            <div class="left-custom-menu-adp-wrap comment-scrollbar">
                <nav class="sidebar-nav left-sidebar-menu-pro">
                    <ul class="metismenu" id="menu1" style="text-align: left;">
                        <li>
                            <router-link class="active" :to="{name: 'Home'}" >
                                <i class="icon nalika-home icon-wrap"></i>
                                <span class="mini-click-non">ACCUEIL</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{name: 'PlanComptable'}" >
                                <i class="icon nalika-smartphone-call icon-wrap"></i>
                                <span class="mini-click-non">PLAN COMPTABLE</span>
                            </router-link>
                        </li>
                        <li>
                            <a class="has-arrow"  aria-expanded="false"><i class="icon nalika-new-file icon-wrap"></i> <span class="mini-click-non">JOURNAL</span></a>
                            <ul class="submenu-angle" aria-expanded="false">
                                <li v-for="type in types" :key="type.id">
                                    <a class="" :href="'/'+type.id+'/'+type.intitule.toLowerCase()" >
                                        <i :class="type.icon"></i>
                                        <span class="mini-sub-pro"> {{ type.intitule.toLowerCase() }}</span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div>
        </nav>
    </div>
</template>

<script>
import axios from 'axios'

export default ({
    name: "LeftSideBar",
    computed: {
        isInitialize(){
            return this.$store.state.isInitialize;
        },
    },
    data(){
        return {
            name: localStorage.getItem("name"),
            email: localStorage.getItem("email"),
            types: this.$store.state.types,
            months: this.$store.state.months,
            exercices: this.$store.state.exercices
        }
    },
    mounted(){
        this.gettypes()
        // let month =  this.getMonth()
        // let exercice =  this.getExercice()            
    },
    methods: {
       gettypes() {
           axios.get('/types-comptables')
           .then((res)=>{
                this.types= res.data.type
           })
       },
       getMonth(){
           axios.get('/mois-comptables')
           .then((res)=>{
                return res.data.mois
           })
       },
       getExercice(){
           axios.get('/exercices-comptables')
           .then((res)=>{
                return res.data.exercice
           })
       },
    }
})
</script>
